module.exports =  {
    "redeemCode": {
        "expired": "eSIM套餐已過期",
        "address": "SM - DP + 地址",
        "scanqrcode": "方式一：掃描二維碼",
        "entertheeSIM": "方式二：輸入啟用碼",
        "assistance": "如有疑問請聯繫客服諮詢。",
        "redeemforanesim": "如何兌換eSIM",
        "inquiry": "兌換/查詢",
        "processing": "兌換/查詢中...",
        "completed": "兌換/查詢成功",
        "redeemesim": "兌換eSIM",
        "failed": "兌換失敗，請聯繫客服",
        "redemptiontime": "兌換時間",
        "error": "兌換異常，請聯繫客服諮詢。",
        "hasexpired": "兌換碼已失效，請輸入正確的兌換碼",
        "hasbeenredeemed": "兌換碼已兌換",
        "canceled": "兌換碼已取消",
        "reachassistance": "兌換碼異常，請聯繫客服諮詢。",
        "invalid": "兌換碼無效，請輸入正確的兌換碼",
        "ok": "我知道了",
        "viewesim": "查看eSIM",
        "information": "查看eSIM資訊",
        "activationcode": "啟用碼",
        "install": "添加eSIM的方式",
        "provide": "僅支持兌換eSIM，有關eSIM套餐的疑問，請聯繫客服諮詢。",
        "viewredeemedcode": "確定查看已兌換碼的eSIM信息？",
        "copysuccessful": "複製成功",
        "before": "請在xxxx-xx-xx前啟用",
        "reissued": "請注意：兌換碼一旦兌換，eSIM QR碼必須在30天內啟用，過期將失效，恕不補發。",
        "correct": "請輸入正確的 ICCID",
        "correctcode": "請輸入正確的兌換碼",
        "entertheredemptioncode": "輸入兌換碼",
        "confirmredemption": "點選兌換",
        "continueredemption": "繼續兌換",
        "slider":"請拖動滑塊完成驗證",
        "verificationfailed":'請按住滑塊，拖動到最右側',
        "verificationpassed":'驗證通過',
        "entertheiccid":'請輸入兌換碼 / ICCID',
        "entercorrecttheiccid":'請輸入正確的兌換碼 / ICCID',
        "activatetime":'請於此日期前啟用：',
        "installeSIM":'立即安裝 eSIM',
        "clickInstallEsim":'點擊此按鈕可立即安裝 eSIM',
        "enterEmail":'請輸入電郵'
    },
    "contactUs":{
        "contactus":'聯繫我們',
        "locales":'繁體中文',
        "responetime":'服務時間：',
        "wechatcustomer":'聯繫微信客服',
        "languages":'語言：中文、英語',
        "available":'*不支持語音通話服務',
        "copy":'複製成功',
    }
}