import Vue from 'vue'
import { debounce } from '@/utils/common'
 
// 定义一个名为 debounce 的指令
Vue.directive('debounce', {
  bind(el, binding){
    let execFunc
    // 在函数传参与不传参调用的时候，打印出来的 binding.value 是不同的
    // v-debounce="getCount" 时，binding.value 是函数
    // v-debounce="getCount(index)" 时，binding.value 是 getCount(index) 的返回值，为 undefinded
    // 所以传参时调用需要包一层，如 v-debounce="[() => {getCount(index)}]"，这样取 [binding.value][0] 即为所需函数
    if(binding.value instanceof Array){
      // 函数传参
      const [func , time = 500] = binding.value
      execFunc = debounce(func, time)
    }else{
      // 函数不传参
      execFunc = debounce(binding.value, 500)
    }
    el.addEventListener('click', execFunc)
  }
})