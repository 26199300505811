<template>
    <div id="main">
      <div class="HC_top">
        <a-row type="flex" justify="space-between" align="top">
          <a-col :xs="10" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="left_top" @click="backHome">
                  <img src="@/assets/images/Tsim_Logo.png" alt="" class="toppic">
                  <span class="topdesc">幫助中心</span>
              </div>
          </a-col>
          <a-col :xs="14" :sm="12" :md="12" :lg="12" :xl="12" style="" class="topdesc2">
              <span class="topcontact" @click="jump_contactus">聯繫我們</span>
              <a-dropdown :trigger="['click']">
                  <a-menu slot="overlay" @click.prevent="handleMenuClick">
                      <!-- <a-menu-item key="1">简体中文</a-menu-item>
                      <a-menu-item key="2">繁體</a-menu-item>
                      <a-menu-item key="3">English</a-menu-item>
                      <a-menu-item key="4">日本語</a-menu-item> -->
                  </a-menu>
                  <a-button>{{ selectValue }}<a-icon type="down" /> </a-button>
              </a-dropdown>
          </a-col>
        </a-row>
        <div class="search" v-if="this.$route.matched[1].path!=='/HelpCenter'">
          <van-search 
              v-model="searchValue" 
              placeholder="請輸入關鍵字" 
              @focus="()=>{this.isOpen = true,this.searchValue=''}"
              @blur="onBlur"
              @clear="fun_clearValue"
              clearable>
          </van-search>
          <div class="select_box" v-if="isOpen">
                  <ul>
                      <li v-for="item in filteredItems" :key="item.id" @click="fun_selectValue(item)">{{ item.name }}</li>
                  </ul>
          </div>
          <van-button class="btn_HCsearc" @click="fun_searchJumrouter">搜尋</van-button>
        </div>
      </div>
      <div class="HC_breadcrumb" v-if="this.$route.matched[1].path!=='/HelpCenter'">
          <a-breadcrumb>
              <a-breadcrumb-item v-for="(item,index) in this.$route.matched" :key="index">
                  <span @click="jumpRouterCrumb(item)">{{ item.meta.title }}</span>
              </a-breadcrumb-item>
          </a-breadcrumb>
      </div>
      <div class="HC_content">
          <router-view/>
      </div>
    </div>
  </template>
  <script>
  export default {
      data() {
          return {
              searchValue:'',
              selectValue:'繁體中文',
              lang:'zh_Tw',
              isOpen:false,
              jumpRouter_id:undefined,
              hideTimer: null, // 延迟隐藏定时器
              items: [
                  { id: '1', name: '如何知道我的手機是否支持eSIM?' },
                  { id: '2', name: '如何在iOS設備上安裝eSIM?' },
                  { id: '3', name: '如何在Android設備上安裝eSIM?' },
                  { id: '4', name: '我可以更換手機安裝eSIM嗎?' },
                  { id: '5', name: '什麼是ICCID?' },
                  { id: '6', name: '如何查詢ICCID?' },
                  { id: '7', name: '如何辨別哪一張卡是我安裝的eSIM?' },
                  { id: '8', name: '如何查詢剩餘流量?' },
                  { id: '9', name: '我可以使用eSIM打電話嗎?' },
                  { id: '10', name: '如何修改eSIM名稱?' },
                  { id: '11', name: '使用完畢後如何刪除eSIM?' },
                  { id: '12', name: '安裝eSIM時顯示失敗，並且提示錯誤訊息' },
                  { id: '13', name: 'eSIM成功安裝，卻無法上網' },
                  { id: '14', name: '如何設置APN' },
                  { id: '15', name: '網路不順暢，訊號顯示3G或E' },
                  { id: '16', name: '手機無法分享熱點' },
              ],
          }
      },
      computed: {
              filteredItems() {
                  return this.items.filter(item => item.name.includes(this.searchValue));
              }
      },
      created () {
          this.$i18n.locale = localStorage.getItem('locale') || this.lang
      },
      mounted () {
            const oScript = document.createElement('script');
            oScript.type = 'text/javascript';
            oScript.id = 'respondio__widget';
            oScript.src = 'https://cdn.respond.io/webchat/widget/widget.js?cId=083249d1e1c3110598b0da6a0775452';
            document.body.appendChild(oScript);
      },
      methods: {
          backHome(){
              this.$router.push({path:'/HelpCenterLayout'})
          },
          handleMenuClick(e) {
              this.selectValue = e.key
              if(e.key == '1'){
                  this.selectValue = '简体中文'
                  this.$i18n.locale = 'zh'
                  localStorage.setItem('locale','zh')
              }else if(e.key == '2'){
                  this.selectValue = '繁體'
                  this.$i18n.locale = 'zh_Tw'
                  localStorage.setItem('locale','zh_Tw')
              }else if(e.key == '3'){
                  this.selectValue = 'English'
                  this.$i18n.locale = 'en'
                  localStorage.setItem('locale','en')
              }else{
                  this.selectValue = '日本語'
                  this.$i18n.locale = 'jp'
                  localStorage.setItem('locale','jp')
              }
          },
          jumpRouterCrumb(item){
              this.$router.push({name:item.name})
          },
          jump_contactus(){
              this.$router.push({path:'/HelpCenter/contactus'})
          },
          fun_selectValue(item){
              this.isOpen = false
              this.searchValue = item.name
              this.jumpRouter_id = item.id
          },
          fun_searchJumrouter(){
            const validIds1 = ['1', '2', '3', '4'];
            const validIds2 = ['5', '6', '7', '8','9','10','11'];
            const validIds3 = ['12','13','14','15','16'];

            let routeName = '';
            let query = {};

            if (validIds1.includes(this.jumpRouter_id)) {
                routeName = 'esiminstallpage';
            } else if (validIds2.includes(this.jumpRouter_id)) {
                routeName = 'esimUsepage';
            } else if (validIds3.includes(this.jumpRouter_id)) {
                routeName = 'esimtroubleshooting';
            } else {
                return;
            }

            query.id = this.jumpRouter_id;
            this.$router.push({
                name: routeName,
                query: query
            });
          },
          fun_clearValue() {
              this.searchValue = "";
              this.jumpRouter_id = undefined
          },
          onBlur() {
              this.hideTimer = setTimeout(() => {
                  this.isOpen = false;
                  if(this.searchValue == '' || this.searchValue == undefined) {
                      this.jumpRouter_id = undefined
                  }
              }, 200);
          },
      },
      beforeRouteEnter (to, from, next) {
          document.title = '幫助中心';
          next();
      },
  };
  </script>
  
  <style lang="less" scoped>
  #main {
  //   height: 100vh;
    background-color: #e7dfd4;
  //   height: 300px;
    .HC_top {
      width: 100%;
      font-size: 16px;
      background-color: #FFFFFF;
      padding: 23px 16px;
      .left_top:hover{
          cursor: pointer;
      }
      .toppic{
          width: 48px;
          vertical-align: baseline;
          margin-right: 14px;
      }
      .topdesc{
          font-size: 16px;
          color: #484B4F;
          font-family: 'NotoSansTC-Bold';
      }
      .topdesc2{
          display:flex;
          justify-content:end;
          align-items:center
          
      }
      
      .topcontact{
          font-size: 14px;
          color: #000000;
          margin-right: 10px;
          margin-top: 8px;
      }
      .topcontact:hover{
          cursor: pointer;
      }
      .ant-btn{
          background-color: #FFFFFF;
          margin-top: 8px;
      }
      .ant-btn:focus{
          color: #F5C17A;
          border-color: #F5C17A;
      }
      .ant-btn:hover{
          color: #F5C17A;
          border-color: #F5C17A;
      }
    }
    .search{
      width: 100%;
      // height: 40px;
      background-color: #FAF2E7;
      margin-top: 19px;
      padding: 4px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .select_box{
          width: 100%;
          max-height: 212px;
          overflow: hidden;
          overflow-y: scroll; /* 开启纵向滚动条 */
          background-color: #FAF2E7;
          border-radius: 8px;
          position: absolute;
          top:102%;
          left: 0px;
          z-index: 99;
          ul li{
              text-indent: 15px;
              line-height: 30px;
          }
          li:hover{
              cursor: pointer;
              background-color: #FFFFFF;
          }
      }
      .van-search{
          width: 100%;
          // height: 40px;
          border-radius: 8px;
          background-color: #FAF2E7;
          padding: 0px;
          .van-search__content{
              height: 33px;
              background-color: #FAF2E7;
          }
      }
      .btn_HCsearc{
          background-color: #F5C17A;
      }
    }
    .HC_breadcrumb{
      margin: 29px 0px 0px 18px;
    }
    .HC_content{
      height: 100vh;
    }
    
  }
  .ant-dropdown-menu{//目前无语言选择时使用，当有多语言时删除
      padding: 0px !important;
  }
  </style>