module.exports =  {
    "redeemCode": {
        "expired": "eSIM有効期限切れ",
        "address": "SM - DP + 住所",
        "scanqrcode": "方法1:QRコードのスキャン",
        "entertheeSIM": "方法2:eSIM 情報を手入力する",
        "assistance": "ご不明な点がございましたら、カスタマーサービスまでお問い合わせください。",
        "redeemforanesim": "eSIMの切替方法",
        "inquiry": "引き換え/問い合わせ",
        "processing": "引き換え/問い合わせ中…",
        "completed": "引き換え/問い合わせ完了",
        "redeemesim": "eSIMの引き換え",
        "failed": "引き換え失敗、サポートセンターまでお問い合わせください。",
        "redemptiontime": "引き換え時間",
        "error": "引き換えエラー、サポートセンターまでお問い合わせ下さい。",
        "hasexpired": "引き換えコード失敗、正しいコードを入力してください。",
        "hasbeenredeemed": "入力いただいたコードは既に引き換え済みです。",
        "canceled": "引き換えコードは取り消しされました。",
        "reachassistance": "引き換えコードに異常が確認されたため、サポートセンターにご連絡ください。",
        "invalid": "引き換えコードは無効です。正しいIDをご入力ください。",
        "ok": "承知しました。",
        "viewesim": "esimを見る",
        "information": "eSIM情報の確認",
        "activationcode": "アクティベーションコード",
        "install": "eSIM方法の追加",
        "provide": "eSIMのみ切替可能です、eSIMパックについてご不明な点がございましたら、サポートセンターにお問い合わせください。",
        "viewredeemedcode": "eSIMコードの表示をしてもよろしいでしょうか。",
        "copysuccessful": "コピー成功",
        "before": "xxxx-xx-xxの前にご使用ください。",
        "reissued": "注意:コード交換後、eSIM QRコードは30日以内に有効化する必要があります。有効期限を過ぎるとQRコードは無効となり、再発行することはできませんのでご注意ください。",
        "correct": "正しいICCIDをご入力してください",
        "correctcode": "正しい引き換えコードをご入力してください",
        "entertheredemptioncode": "引き換えコードをご入力してください",
        "confirmredemption": "引き換えを確認する",
        "continueredemption": "引き換えを続ける",
        "slider":"スライダーをドラッグして、検証を完了してください。",
        "verificationfailed":'スライダーを押し続けて、右端までドラッグしてください。',
        "verificationpassed":'検証に成功しました。',
        "entertheiccid":'引き換えコード・ICCIDを入力してください',
        "entercorrecttheiccid":'正確の引き換えコード・ICCIDを入力してください',
        "activatetime":'この日までにアクティベートしてください：',
        "installeSIM":'今すぐeSIMを追加します',
        "clickInstallEsim":'ボタンを押してすぐにeSIMを追加できます',
        "enterEmail":'メールアドレスを入力してください'
    },
    "contactUs":{
        "contactus":'お問い合わせ',
        "locales":'日本語',
        "responetime":'サービス時間：',
        "wechatcustomer":'WeChat カスタマーサービス',
        "languages":'対応言語：中国語・英語',
        "available":'*電話での対応は受け付けておりません。',
        "copy":'コード成功',
    }
}