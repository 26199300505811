module.exports =  {
    "redeemCode": {
        "expired": "The eSIM has expired.",
        "address": "SM - DP + address",
        "scanqrcode": "Option 1: Scan QR code",
        "entertheeSIM": "Option 2: Enter the eSIM activation Information",
        "assistance": "If you have any inquiries, please reach out to technical support team for assistance.",
        "redeemforanesim": "How to redeem for an eSIM",
        "inquiry": "Redeem / Inquiry",
        "processing": "Please wait, processing is in progress.",
        "completed": "Redeem / Inquiry process has been completed.",
        "redeemesim": "Redeem for an eSIM",
        "failed": "Redemption failed, please reach out to technical support team for assistance.",
        "redemptiontime": "Redemption time",
        "error": "Redemption error, please reach out to technical support team for assistance.",
        "hasexpired": "Redemption code has expired, please enter the correct redemption code.",
        "hasbeenredeemed": "Redemption code has been redeemed.",
        "canceled": "Redemption code has been canceled.",
        "reachassistance": "Redemption code error, please reach out to technical support team for assistance.",
        "invalid": "Invalid redemption code, please enter the correct redemption code.",
        "ok": "Ok",
        "viewesim": "View eSIM",
        "information": "View eSIM information",
        "activationcode": "The activation code",
        "install": "Ways to install the eSIM",
        "provide": "The page only provide support for eSIM redemption. If you have any questions about using eSIM, please contact technical support team.",
        "viewredeemedcode": "Confirm to view the redeemed eSIM code",
        "copysuccessful": "Copy successful.",
        "before": "Please activate the eSIM before [xxxx-xx-xx]",
        "reissued": "Note: Once the redemption code is redeemed, the eSIM QR code must be activated within 30 days, otherwise it will expire and cannot be reissued.",
        "correct": "Please enter the correct ICCID.",
        "correctcode": "Please enter the correct Redemption code.",
        "entertheredemptioncode": "Enter the Redemption code",
        "confirmredemption": "Confirm redemption",
        "continueredemption": "Continue redemption",
        "slider":"Please drag the slider to complete the verification",
        "verificationfailed":'Please press and hold the slider and drag it to the far right',
        "verificationpassed":'Verification passed',
        "entertheiccid":'Please enter the redemption code / ICCID',
        "entercorrecttheiccid":'Please enter the correct redemption code / ICCID',
        "activatetime":'Please activate eSIM plan before :',
        "installeSIM":'Install eSIM Now',
        "clickInstallEsim":'Tab this button to install eSIM immediately',
        "enterEmail":'Please enter your email address'
    },
    "contactUs":{
        "contactus":'Contact Us',
        "locales":'English',
        "responetime":'Response time：',
        "wechatcustomer":'WeChat Customer Service',
        "languages":'Languages：Chinese , English',
        "available":'*No telephone support is available.',
        "copy":'Copied',
    }
}